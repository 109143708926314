import { call, select } from "redux-saga/effects";

import { sendClientInfo } from "../api/fraudAnalysis";
import { getIdentifier } from "../selectors/session";

function* handleSendBrowserInfo() {
  const msisdn = yield select(getIdentifier);
  const { origin, orientation, screen, navigator } = window;
  const { height, width, pixelDepth } = screen;
  const {
    appCodeName,
    appName,
    appVersion,
    connection,
    cookieEnabled,
    language,
    platform,
    userAgent,
    vendor,
  } = navigator;
  const { downlink, effectiveType, rtt } = connection;

  yield call(
    sendClientInfo,
    {
      window: {
        origin,
        orientation,
        screen: {
          height,
          width,
          pixelDepth,
        },
      },
      navigator: {
        appCodeName,
        appName,
        appVersion,
        connection: {
          downlink,
          effectiveType,
          rtt,
        },
        cookieEnabled,
        language,
        platform,
        userAgent,
        vendor,
      },
    },
    msisdn
  );
}

export { handleSendBrowserInfo };
